<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="320"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('app.jugadores.add_to_team_question') }}
      </v-card-title>
      <v-card-actions>
        <v-btn
          color="red darken-1"
          text
          @click="cancel"
        >
          {{ $t('app.general.no') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="green dark"
          @click="confirm"
        >
          {{ $t('app.general.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'JugadoresAddToTeamQuestion',
    data () {
      return {
        jugador: false,
        persona: false,
        dialog: false,
      }
    },
    mounted () {
      EventBus.$on('jugadores-add-to-team-question', (item) => {
        this.jugador = item.jugador
        this.dialog = true
      })
    },
    methods: {
      async confirm () {
        this.dialog = false
        EventBus.$emit('jugadores-add-to-team', {
          jugador: this.jugador
        })
      },
      async cancel () {
        this.dialog = false
        EventBus.$emit('reload-items')
      },
    },
  }
</script>

<style scoped>

</style>
